export default {
  'en': {
    'PROFILE_LINK': '/manage-profile/#/my_profile',
    'HELP_CONTENT': 'If you have an active Royal LePage email account, your username is your address, i.e. xxxxx@royallepage.ca. If you do not have a Royal LePage email address, please contact your office or Customer Care to confirm your username. If you have any questions or need help, contact us at help@royallepage.ca or call 1-877-757-4545.',
    'AWARD_INFO': '<p>The award tracker information displayed is based on gross, closed and collected income for the current sales award year (November 16 to November 15).</p><p>The commission income displayed is not based on pending trades but on closed and collected trades, and is current as of your brokerage\'s last financial upload to Royal LePage.</p><p>Team size is determined by averaging the daily size of the team member count over the course of the sales award year. If a team size flunctuates over the course of the sales awards year, their qualifying thresholds may also change as a result. Full award qualifying thresholds can be found <a href = "/award-levels-by-market/">here</a>.</p><p>This awards tracker takes into account the 2% variance for GCI permitted by Royal LePage.</p><p><a href="/awards-and-recognition-programs/">Visit this page</a> for information on the Royal LePage Awards and Recognition Program, and Team Guidelines.</p>'
  },
  'fr': {
    'Welcome %': 'BIENVENUE %',
    'Login': 'Connexion',
    'Forgot Password': 'Mot de passe oublié',
    'Français': 'English',
    'Help': 'Aide',
    'WELCOME': 'BIENVENUE',
    'Email Address': 'Adresse e-mail',
    'User Name': 'Nom d\'utilisateur',
    'LOG IN TO': 'SE CONNECTER AU',
    'rlpNetwork': 'Réseaurlp',
    'Password': 'Mot de passe',
    'Login failed': 'Échec de la connexion ',
    'Invalid username or password': 'Nom d\'utilisateur ou mot de passe invalide',
    'The following errors have occurred': 'Les erreurs suivantes ont eu lieu',
    'Director\'s Platinum Award': 'Attestation Platine De Directeur',
    'Diamond Award': 'Attestation Diamant',
    'Red Diamond Award': 'Prix Diamant rouge',
    'President\'s Gold Award': 'Palme D\'Or Du Président',
    'Master Sales Award': 'Maître Vendeur',
    'Sales Achievement Award': 'Vendeur Émérite',
    'S.A.': 'VE',
    'M.S.': 'MV',
    'P.G': 'PO',
    'D.P': 'PP',
    'D.A.': 'PD',
    'R.D.': 'DR',
    'Work Faster.Work Smarter.rlpNetwork.': 'Travaillez plus rapidement. Travaillez mieux. Réseaurlp.',
    'System Status': 'Voir l\'état des systèmes',
    'Welcome': 'BIENVENUE',
    'LANGUAGES': 'LANGUES',
    'PROVINCE': 'PROVINCE',
    'CITY': 'VILLE',
    'SPECIALTIES': 'SPECIALITES',
    'DESIGNATIONS': 'DÉSIGNATIONS',
    'First Name': 'Prénom',
    'FirstName': 'Prénom',
    'DELETE': 'SUPPRIMER',
    'Page Loading': 'Chargement de la page',
    'Sign out': 'Déconnexion',
    'About Us': 'À propos de nous',
    'Public Relations': 'Relations publiques',
    'Contact Information': 'Informations de contact',
    'Legal': 'Légal',
    'Privacy Policy': 'Politique de la vie privée',
    'Legal & Business': 'Avis juridique',
    'Terms of Use': 'Conditions d\'utilisation',
    'User Guides': 'Guides d\'utilisation',
    'Feedback': 'Commentaires',
    'Customer Care': 'Assistance à la clientèle',
    'Profile': 'Profil',
    'Sign Out': 'Déconnexion',
    'This email will receive Royal LePage communications': 'Cet courriel recevra les communications de Royal LePage',
    'Profile Preview': 'Aperçu du Profil',
    'Manage Users': 'Gestion d\'utilisateur',
    'New User': 'Nouvel Utilisateur',
    'Username': 'Nom d\'utilisateur',
    'Last Name': 'Nom de famille',
    'LastName': 'Nom de famille',
    'Brokerage': 'Courtage',
    'Office': 'Bureau',
    'Status': 'Statut',
    'Active': 'Actif',
    'Inactive': 'Inactif',
    'Advanced Search': 'Recherche Avancée',
    'Clear Search': 'Effacer la recherche',
    'Search': 'Recherche',
    'Name': 'Nom',
    'Primary Location': 'Bureau principal',
    'Permission Level': 'Niveau de permission',
    'Last Logged In': 'Dernière connexion',
    'Current Status': 'Statut actuelle',
    'Manage Status': 'Gérer l\'état',
    'Your search did not return any results. Please refine your search term(s).': 'Votre recherche n\'a retourné aucun résultat. S\'il vous plaît affiner votre recherche.',
    'Referral Directory': 'Répertoire de référencement',
    'Search by city or agent': 'Recherche par ville ou courtier',
    'Filter By…': 'Filtrer par…',
    'Networking': 'Réseau',
    'Professional Experience': 'Expérience professionnelle',
    'Professional Experience English': 'Expérience professionnelle anglais',
    'Professional Experience French': 'Expérience professionnelle français',
    'Internal Remarks': 'Remarques internes',
    'Awards': 'Distinctions',
    'Skills and Abilities': 'Compétences',
    'Professional Designations': 'Désignations professionnelles',
    'View ad': 'Voir l\'annonce',
    'Please ensure that you click \'Save\' after your photo has been uploaded. If you do not click Save, your photo will not display on www.royallepage.ca.': 'Veuillez cliquer "Enregistrer" après avoir téléhargé votre photo. Si vous ne cliquez pas sur Enregistrer, votre photo ne s\'affichera pas à www.royallepage.ca',
    'Update Photo': 'Mise à jour de photos',
    'Reset Password': 'Réinitialiser le mot de passe',
    'Cancel': 'Annuler',
    'Edit Profile': 'Profil',
    'Speaker/Trainer': 'Conférencier / Formateur',
    'Hold down the <Ctrl> key to select multiple items.': 'Appuyez <Ctrl> pour selectionner plusieurs éléments',
    '* Indicates a required field': '* Indique un champ obligatoire',
    'Save Changes': 'Enregistrer',
    'Basic Information': 'Information de base',
    'CONTACT INFORMATION': 'Informations de contact',
    'MARKETING': 'MARKETING',
    'SKILLS': 'Aptitudes et compétences',
    'BIO': 'BIO',
    'Invalid Current Password, The password must have at least 8 characters and it should not exceed 40 characters. The Password can contain only alpha-numeric characters': 'Mot de passe actuel invalide, Le mot de passe doit comporter au moins 8 caractères et ne doit pas dépasser 40 caractères.Mot de passe peut contenir que des caractères alphanumériques',
    'Invalid New Password, The password must have at least 8 characters and it should not exceed 40 characters. The Password can contain only alpha-numeric characters': 'Nouveau mot de passe invalide, Le mot de passe doit comporter au moins 8 caractères et ne doit pas dépasser 40 caractères.Mot de passe peut contenir que des caractères alphanumériques',
    'Quick LINKS': 'Liens Rapides',
    'Add': 'Ajouter',
    'Add/Remove Links': 'Ajouter / Supprimer Liens rapides',
    'Link Title': 'Lien rapide Nom',
    'Link URL': 'Lien rapide URL',
    'Save': 'Enregistrer',
    'Link Title cannot be empty or less than five characters or greater than 50 characters.': 'Le titre du lien ne peut pas être vide. Le titre du lien doit comporter entre 5 et 50 caractères.',
    'Invalid Link URL': 'URL de lien non valide',
    'Name on Tag': 'Nom à afficher',
    'Previous affiliation details': 'Détails de l\'affiliation précédente',
    'Manage Users - New User': 'Gestion d\'utilisateur - Nouvel utilisateur',
    'Personnel Type': 'Type de personnel',
    'Last name': 'Nom de famile',
    'Select User Name / Email': 'Sélectionnez Nom d\'utilisateur / Email',
    'Check Availability Now': 'Vérifier la disponibilité en ce moment',
    'Select Password': 'Sélectionnnez Mot de passe',
    'Primary Language': 'Langue principale',
    'Recruitment Source': 'Source de recrutement',
    'RecruitmentSource': 'Source de recrutement',
    'Previous Affiliation': 'Précédente affiliation',
    'PreviousAffiliation': 'Précédente affiliation',
    'Activation Date': 'La date d\'activation',
    'ActivationDate': 'La date d\'activation',
    'Sales kit required?': 'Kit de vente obligatoire?',
    'Reset': 'Réinitialiser',
    'Create Account': 'Créer Utilisateur',
    'In order to edit your name, please contact your office administrative staff, or Customer Care at 1-877-757-4545.': 'Pour changer votre nom, veuillez contacter votre équipe administrative, ou Assistance à la Clientèle au 1-877-757-4545.',
    'Recovery Email': 'Courriel de récupération',
    'Communications Email': 'Courriel à utiliser pour communiquer',
    'Official First Name': 'Prénom officiel',
    'Middle Name': 'Deuxième nom',
    'Official Last Name': 'Nom de famille officiel',
    'Salutation': 'Salutation',
    'Title': 'Titre',
    'Preferred Language': 'Langue préférée',
    'RLP Google Account': 'RLP compte Google',
    'Preferred Email Address': 'Courriel principal',
    'Start Date with RLP': 'Date de début avec RLP',
    'RLP Start Date': 'Date de début avec RLP',
    'Home Phone Number': 'Numéro de téléphone',
    'Office Phone Number': 'Téléphone bureau',
    'Cell Phone Number': 'Numéro de téléphone cellulaire',
    'Toll Free Phone Number': 'Numéro de téléphone sans frais',
    'Fax Number': 'Numéro de télécopieur',
    'Back Office Number': 'Numéro d\'arrière-guichet',
    'CREA ID': 'CREA ID',
    'Centris ID': 'Centris ID',
    'Other Locations': 'Autres bureaux',
    'Areas Serviced': 'Zones desservies',
    'Clear selection': 'Effacer les sélection',
    'Personal Website Address': 'Site Web personnel',
    'Types of Mobile Devices': 'Types d\'appareils mobiles',
    'Facebook': 'Facebook',
    'GooglePlus': 'GooglePlus',
    'Instagram': 'Instagram',
    'LinkedIn': 'LinkedIn',
    'Twitter': 'Twitter',
    'YouTube': 'YouTube',
    'URL is invalid': 'URL est invalide',
    'Languages Spoken': 'Langues parlées',
    'Specialties': 'Spécialités',
    'Year Licensed as a Broker': 'Courtier depuis (année)',
    'Year Licensed as an Agent': 'Année d\'obtention du permis',
    'Year of Birth': 'Année de naissance',
    'Gender': 'Sexe',
    'Highest Level of Education': 'Niveau de scolarité',
    'Internal remarks': 'Remarques internes',
    'Invalid email address.': 'Courriel invalide',
    'First Name is a required field.': 'Le prénom est requis',
    'Last Name is a required field.': 'Le nom est requis',
    'Personnel Type is a required field.': 'Le type de personnel est requis',
    'Preferred Language is a required field.': 'La langue préférée est requise',
    'RLP Google Account is a required field.': 'RLP compte Google est requis.',
    'Input value is too long.': 'La valeur d\'entrée est trop longue.',
    'Recruitment Source is a required field.': 'La source de recrutement est requise.',
    'OACIQ ID': 'OACIQ ID',
    'Public': 'Public',
    'Extension': 'Extension',
    'Year': 'Année',
    'ADD': 'Ajouter',
    '[Select]': '[Sélectionnez]',
    'Marketing': 'MARKETING',
    'Skills and Competencies': 'Aptitudes et compétences',
    'These remarks will only show in rlpNetwork\'s Referral Directory. Use this field to describe your professional experience, referral details and to promote and set yourself apart': 'Ces remarques s’afficheront seulement sur le Répertoire de référencement du Réseaurlp. Remplissez ce champ pour décrire votre expérience professionnelle et toutes autres détails afin de vous promouvoir et de vous démarquer.',
    'Find a Guest Speaker': 'Trouver un conférencier',
    'Compensation': 'Rénumération',
    'Speaker Bio': 'Biographie du Conférencier',
    'Available to Present About': 'Disponible à présenter à propos',
    'Willing To Travel': 'Disposé à voyager',
    'Yes': 'Oui',
    'No': 'Non',
    'Province': 'Province',
    'Brokerage Name': 'Agence',
    'Location': 'Emplacement',
    'Contact Name': 'Nom du contact',
    'Topic': 'Sujet',
    'Contact Number': 'Numéro de contact',
    'Date': 'Date',
    'Notes': 'Remarques',
    'Expertise': 'Compétence',
    'New Event': 'Nouvel évènement',
    'Speaking Events': 'Évènements',
    'Female': 'Femme',
    'Male': 'Homme',
    'All': 'Tout',
    'INACTIVE': 'Inactif',
    'ACTIVE': 'Actif',
    'REACTIVATE': 'RÉACTIVER',
    'TERMINATE': 'RÉSILIER',
    'Terminating': 'Terminaison',
    'Reason For Leaving': 'Motif de résiliation',
    'End Date': 'Date de fin',
    'Future Affiliation': 'Affiliation future',
    'Do You Wish To Transfer Google Drive Data': 'Souhaitez-vous transférer les données de Google Disque? ',
    'Note: Only Google Drive Files will be transferred. Email content is not and will be only available if user is reactivated within 30 days.': 'Remarque : Seuls les fichiers présents sur Google Disque sont transférés. Le contenu des courriels n’est pas transféré.',
    'Terminate': 'RÉSILIER',
    'Future Affiliation is mandatory': 'Une affiliation future est obligatoire.',
    'End Date is mandatory': 'La date de fin est obligatoire',
    'Reason for Leaving is mandatory': 'La raison de partir est obligatoire',
    'Designated user for Google Data Transfer is not selected': 'Vous n’avez pas sélectionné d’utilisateur désigné pour le transfert des données de Google Disque',
    'Future Affiliation Detail': 'Détail de l\'affiliation future',
    'Reactivate Account': 'Réactiver',
    'Sales Kit Required?': 'Kit de vente obligatoire?',
    'English': 'Anglais',
    'French': 'Français',
    'Previous Affiliation details': 'Détails de l\'affiliation précédente',
    'View Agent Matches': 'Voir courtiers correspondants',
    'View City/Area Serviced Matches': 'Voir villes ou zones desservies correspondantes',
    'View Company Matches': 'Voir agences correspondantes',
    'Total': 'Total',
    'Filter': 'Filtre',
    'No data found for your search': 'Aucune donnée trouvée pour votre recherche',
    'Transfer Google Drive Data To': 'Transférer des données Google Drive vers',
    'Do you wish to transfer Google Drive Data is mandatory': 'Souhaitez-vous transférer les données de Google Disque est obligatoires',
    'Invalid access': 'Accès invalide',
    'Username cannot be empty': 'Nom d\'utilisateur ne peut pas être vide',
    'Username cannot contain domain': 'Nom d\'utilisateur ne doit pas contenir le nom de domaine',
    'Is unavailable': 'est indisponible',
    'Is available': 'est disponible',
    'is required.': 'est requis.',
    ' is required.': ' est requis.',
    'First name must not exceed 50 characters.': 'Le prénom ne doit pas dépasser 50 caractères',
    'Last name must not exceed 50 characters.': 'Le nom de famille ne doit pas dépasser 50 caractères',
    'Email/Username must not exceed 128 characters (including domain).': 'Courriel / nom d\'utilisateur ne doit pas dépasser 128 caractères (domaine inclusive)',
    'StartDate': 'Date de début',
    'Password can contain only alpha-numeric characters. Remember that the password must have at least 8 characters and it should not exceed 40 characters.': 'Le mot de passe ne peut contenir que des caractères alphanumériques. Le mot de passe doit aussi comporter au moins 8 caractères et un maximum 40.',
    'Email/Username should not contain domain name.': 'Courriel / nom d\'utilisateur ne doit pas contenir le nom de domaine.',
    'Current Password': 'Mot de passe actuel',
    'New Password': 'Nouveau mot de passe',
    'Confirm Password': 'Confirmez le mot de passe',
    'THE PASSWORD WAS SUCCESSFULLY SAVED': 'Le mot de passe a été enregistré avec succès',
    'New password and confirm password do not match': 'L\'ancient et le nouveau mot de passe ne concorde pas',
    'Invalid OACIQ Id': 'L\'identifiant OACIQ n\'est pas valide',
    'Previous Affiliation Details must not exceed 50 characters.': 'Les détails de l\'affiliation précédente ne doivent pas dépasser 50 caractères',
    'Delete': 'Supprimer',
    'The user has been terminated successfully': 'Cet utilisateur a été terminé avec succès',
    'The user was successfully created': 'Utilisateur Créé',
    'The user has been reactivated successfully': 'Utilisateur réactivé',
    'Select Province First': 'Choisissez votre province',
    'Select Brokerage First': 'Choisissez votre courtage',
    'Award selection and Award Year is mandatory': 'Selection de prix et année d\'attribution sont obligatoire',
    'Brokerage Name is Mandatory': 'Courtage est obligatoire',
    'Location is Mandatory': 'Bureau est obligatoire',
    'Topic is Mandatory': 'Sujet est obligatoire',
    'Date is Mandatory': 'La date est obligatoire',
    'Invalid Contact Number': 'Le numéro de contact est invalide',
    'Willing To Travel is mandatory': 'Disposéà voyager est obligatoire',
    ' is mandatory': ' est obligatoire',
    'Current password does not match the password entered.': 'Le mot de passe actuel ne correspond pas au mot de passe saisi',
    'Province is mandatory': 'Province est obligatoire',
    'Brokerage is mandatory': 'Courtage est obligatoire',
    'Click here to add details about your selection above': 'Cliquez ici pour ajouter des détails sur votre sélection ci-dessus',
    '[Select Province First]': '[Choisissez votre province]',
    '[Select Brokerage First]': '[Choisissez votre courtage]',
    'Brokerage is Mandatory': 'Courtage est obligatoire',
    'Location is mandatory': 'Bureau est obligatoire',
    'Invalid ': 'Invalide ',
    ' field': '&nbsp',
    'Other': 'Autre',
    'OACIQ ID should be one letter followed by four numbers.': 'Le numéro de titulaire OACIQ contient une lettre suivie de quatre chiffres.',
    'The information was successfully saved': 'Profil mis à jour avec succès.',
    'Topic is mandatory': 'Le champs sujet est obligatoire.',
    'No results found': 'Aucun résultat trouvé',
    'Recent Speaking Events': 'Événements récents',
    'TOPICS': 'Sujets',
    'Forgot Password Step 1': 'Mot de passe oublié? Étape 1',
    'Please enter your @royallepage.ca email address': 'Veuillez entrer votre courriel Royal LePage',
    'If you do not know your Royal LePage email address, please contact your office or Customer Care to confirm your email address. If you need assistance resetting your password, please contact Customer Care. You can reach us by emailing help@royallepage.ca or calling us at 1-877-757-4545.': 'Si vous ne connaissez pas votre courriel Royal LePage, veuillez contacter votre bureau ou le Centre d\'assistance à la clientèle pour confirmer votre courriel. Si vous avez besoin d\'aide pour réinitialiser votre mot de passe, veuillez contacter le Centre d\'assistance à la clientèle. Prenez contact avec nous par courriel à aide@royallepage.ca, ou par téléphone au 1 877 757-4545.',
    'Email Address/User Name not provided': 'Courriel/Nom d\'utilisateur non fourni',
    'Continue': 'Continuer',
    'Forgot Password Step 2': 'Mot de passe oublié? Étape 2',
    'Would you like password reset instructions sent to the email address below?': 'Souhaitez-vous recevoir les instructions de réinitialisation du mot de passe à l’adresse courriel suivante?',
    'If you no longer have access to the recovery email on file, you will need to contact Customer Care. You can reach us at help@royallepage.ca or call 1-877-757-4545.': 'Si vous avez perdu l’accès au courriel de récupération contenu dans vos dossiers, vous devez communiquer avec le Centre d’assistance à la clientèle. Prenez contact avec nous par courriel à aide@royallepage.ca, ou par téléphone au 1 877 757-4545.',
    'Send': 'Envoyer',
    'You do not have a recovery email on file. Please contact Customer Care. You can reach us at help@royallepage.ca or call 1-877-757-4545.': 'Vos dossiers ne contiennent pas de courriel de récupération. Veuillez communiquer avec le Centre d’assistance à la clientèle.',
    'Forgot Password Step 3': 'Mot de passe oublié? Étape 3',
    'Password reset instructions has been sent to ': 'Les instructions de réinitialisation du mot de passe ont été envoyées à l’adresse ',
    'Remember: Changing your password here will require you to change your email password on all of your devices.': 'Rappelez-vous: pour changer votre mot de passe ici, vous devrez changer votre mot de passe de courriel sur tous vos appareils.',
    'Go to Login Page': 'Continuer',
    'Crop & Save': 'Rogner & Enregistrer',
    'Drag and drop the rectangle to select the area of the photo you would like to use. When you are finished press "Crop & Save" or "Cancel" to go back to your profile.': 'Cliquer (bouton gauche de la souris) sur le rectangle de sélection sur la photo et retener le bouton pour déplacer le réctangle sur la section de la photo que vous désirez utiliser. Lorsque vous aurez terminer, appuyer sur "Rogner & Enregistrer" ou "Annuler" pour retourner à votre profil.',
    'Select Date': 'Sélectionner une date',
    'This date must be your start date with Royal LePage, and not the year you started selling real estate.': 'Cette date doit être la date de votre début avec Royal LePage et non l\'année où vous avez commencé à vendre de l\'immobilier.',
    'ERROR: The photo you have selected is too big. Please select a photo that is 450 x 600 pixels or smaller.': 'ERREUR: La photo que vous avez sélectionnée est trop grande. Veuillez sélectionner une photo de 450 x 600 pixels ou moins.',
    'AWARD_INFO': '<p>L\'information publiée dans l\'outil de suivi de distinction est fondé sur les revenus de rétributions brutes notariées et encaissées pour l\'année de prix en cours (16 novembre au 15 novembre).</p><p>Le revenu de rétributions brutes affiché n\'est pas fondé sur des transactions en attente, mais bien sur des revenus de transactions notariées et encaissées, et reflète les données les plus récentes téléversées par l\'agence dans le système.</p><p>La taille de l\'équipe est déterminée en calculant la moyenne quotidienne du nombre de membres d\'équipe au cours de l\'année de prix en vigueur. Si la taille de l\'équipe fluctue au cours de la période de reconnaissance, leurs seuils de qualification pourraient également changer. Les seuils de qualification sont disponibles <a href = "/niveaux-de-distinction-par-marche/">ici</a>.</p><p>Cet outil de suivi tient compte de l\'écart de 2% autorisé par Royal LePage.</p><p><a href = "/programmes-de-prix-et-reconnaissance/">Visitez cette page</a> pour obtenir des informations sur le programme de prix et de reconnaissance Royal LePage, ainsi que sur les lignes directrices en matière d’équipe.</p>',
    'username': 'nomdutilisateur',
    'Award is duplicated': 'Le prix est dupliqué',
    'rlpsphere': 'sphererlp',
    'rlpNetwork_rlpSPHERE-widget-launch-en.png': 'rlpNetwork_rlpSPHERE-widget-launch-fr.png',
    'Please select a team': 'Veuillez sélectionner une équipe',
    'Team Lead': 'Chef d’équipe',
    'Team Lead is required.': 'Chef d’équipe est requis.',
    'Team': 'équipe',
    'This user is not part of a team.': 'Cet utilisateur ne fait pas partie d\'une équipe.',
    'none': 'aucune',
    'Team lead can not be Admin or Licensed Assistant or Unlicensed Assistant or Finance staff.': 'Le chef d\'équipe ne peut être un administrateur, un assistant licencié ou un assistant non licencié ou Personnel des finances.',
    'Your search returned too many results. Please refine your search.': 'Votre recherche a généré trop de résultats. Veuillez affiner votre recherche.',
    'PROFILE_LINK': '/manage-profile-fr/#/my_profile',
    'HELP_CONTENT': 'Si vous avez un compte courriel Royal LePage, votre code d\'utilisateur est votre adresse courriel, par exemple, xxxxx@royallepage.ca. Si vous n\'avez pas un compte Royal LePage, veuillez contacter votre bureau ou notre Centre d\'assistance à la clientèle. Si vous avez besoin d\'aide, veuillez nous rejoindre par courriel à aide@royallepage.ca ou par téléphone au 1-877-757-4545.',
    'Business Analytics Pipeline': 'Rapports de performance',
    'My CRM': 'Mon CRM',
    'Sphere + Prospect': 'Sphère + Prospect',
    'New Lead': 'Nouveau Lead',
    'Active Lead': 'Lead actif',
    'Client': 'Client',
    'Under Contract': 'Sous contrat',
    'Closed': 'Complété',
    'Today\'s Activities': 'Activités d\'aujourd\'hui',
    'All Activities': 'Toutes les activités',
    'Actions': 'Actions',
    'Create a Smart Campaign': 'Créer une campagne intelligente',
    'Create a Landing Page': 'Créer un page de destination externe',
    'Update your rlpSPHERE Website': 'Mettre à jour votre site Web de la SPHÈRErlp',
    'Launch': 'Lancer',
    '/manage-user/': '/gestion-dutilisateur/',
    'Displaying': 'Affichage',
    'End date cannot be before Start date': 'La date de fin ne peut pas être antérieure à la date de début',
    'Start Date': 'Date de début',
    'If you selected Other above, please enter the agent\'s future brokerage name here': 'Si vous sélectionnez « Autre », veuillez indiquer le nom de la future agence',
    'Start date is between another period of employment': 'La date de début se situe entre une autre période d\'emploi',

    'Manage Locations': 'Gestion des emplacements',
    'Web and Social Media': 'Web et médias sociaux',
    'Location Website Address': 'Adresse du site Web',
    'Display Name': 'Nom affiché',
    'Try it': 'Essayer',
    'Phone Number': 'Numéro de téléphone',
    'Fax': 'Numéro de télécopieur',
    'Email': 'Courriel',
    ' is invalid.': ' est invalide',
    'Facebook is invalid.': 'Facebook est invalide',
    'LinkedIn is invalid.': 'LinkedIn est invalide',
    'Twitter is invalid.': 'Twitter est invalide',
    'YouTube is invalid.': 'YouTube est invalide',
    'Phone Number is invalid.': 'Invalide Numéro de téléphone',
    'Fax is invalid.': 'Invalide Numéro de télécopieur',
    'Email is invalid.': 'Courriel invalide',
    'to': 'à',
    'Please submit a backup report for each agent you have flagged as potential candidates for the National Chairman\'s Club Award and /or Top 10 Award': 'Please submit a backup report for each agent you have flagged as potential candidates for the National Chairman\'s Club Award and /or Top 10 Award.',
    'Upload': 'Télécharger',
    'Select All': 'Tout Selectionner',
    'Click here for further guidelines': 'Cliquez ici pour plus de directives.',
    'CONFIRM': 'CONFIRMER',
    'Top ten candidate': 'Candidat(e) au Club des dix',
    'An error occured. Please try again.': 'Une erreur s\'est produite. Veuillez réessayer!',
    'Confirmed successfully.': 'Confirmé avec succès.',
    'Please select a location first.': 'Veuillez d\'abord sélectionner un emplacement',
    'Files uploaded successfully.': 'Fichiers téléversés avec succès',
    'Files uploaded unsucessfully! Please upload files again.': 'Le téléversement n\'a pas fonctionné. Veuillez essayer de nouveau.',
    'You may adjust an agent’s GCI by clicking the edit pencil and entering a positive or negative amount into the RLP Income Adjustment field next to the desired user. This will not affect your FRS invoice income as these changes are considered for awards purposes only. You will see the changes to the user’s GCI reflected in the Total Awards Income field once you click out of editing mode.': 'Vous pouvez ajuster les revenus de rétributions brutes d’un courtier en cliquant sur le crayon de modification et en saisissant un montant d’ajustement positif ou négatif dans le champ Ajustement du revenu RLP à côté de l’utilisateur en question. Cela n’affectera pas votre système de rapports financiers (SRF), car ces changements sont considérés uniquement à des fins de distinctions. Les ajustements apportés aux revenus de rétributions brutes de l’utilisateur seront reflétés dans le champ Revenu total de l’année de référence une fois que vous aurez quitté le mode de modification.',
    'Adjusting Agent GCI': 'Ajustements des revenus de rétributions brutes d’un courtier',
    'Adjusting Unit Count': 'Ajustement du nombre d’unités de transaction',
    'You may adjust the number of units allocated to an Agent by clicking on the edit pencil next to a user and entering a positive or negative number in the RLP Unit Adjustment field. You will see these changes reflected in the Total Awards Units once you click out of the editing mode.': 'Vous pouvez ajuster le nombre de transactions attribuées à un courtier en cliquant sur le crayon de modification à côté de l’utilisateur en question et en saisissant le nombre positif ou négatif d’ajustement à appliquer dans le champ Ajustement des transactions RLP. Les ajustements seront reflétés dans le champ Nombre total de transactions de l’année de référence une fois que vous aurez quitté le mode de modification.',
    'As a reminder, for sales awards purposes a unit is defined as follows:': 'À titre de rappel, aux fins des prix en matière de ventes, une unité de transaction est définie comme suit :',
    'represents a buyer on a closed and collected deal - 1 unit': 'représente un acheteur dans une transaction conclue et perçue, cela compte comme 1 unité de transaction;',
    'represents a seller on a closed and collected deal - 1 unit': 'représente un vendeur dans une transaction conclue et perçue, cela compte comme 1 unité de transaction;',
    'represents both a buyer and a seller on a closed and collected deal - 2 units': 'représente un acheteur et un vendeur dans une transaction conclue et perçue, cela compte comme 2 unités de transaction;',
    'co-lists or co-sells with another REALTOR® on a closed and collected deal - 1/2 unit': 'inscrit ou vend une propriété avec un autre courtier immobilier dans une transaction conclue et perçue, cela compte comme une demie unité de transaction;',
    'Rentals/leases do not count as a unit [nor do referral fees earned count as a unit]': 'Les locations résidentielles et commerciales ne sont pas considérées comme une unité de transaction [les commissions de référencement gagnées ne sont pas non plus considérées comme une unité de]',
    'Adjusting Previous Brand Income & Units': 'Ajustement du revenu et des transactions réalisés antérieurement auprès d’une autre bannière',
    'The purpose of these fields is to allow GCI and units earned within the awards year to be submitted for an agent who has joined Royal LePage from a competing brand. You may enter GCI and Units into the Previous Brand Income and Units fields in the same manner as you would for the Royal LePage income and units fields. The changes made will be reflected in the Total Award Income & Units fields once you click out of editing mode.': 'L’objectif de ces champs est de permettre la soumission des revenus de rétributions brutes et des unités de transaction accumulés au cours de l’année de référence pour un courtier qui a quitté une autre bannière pour se joindre à Royal LePage. Vous pouvez saisir les revenus de rétributions brutes et les transactions dans les champs concernant le revenu et les unités de transaction auprès d’une bannière précédente de la même manière que vous le feriez pour Royal LePage. Les ajustements apportés seront reflétés dans les champs Nombre total d’unités de transaction et Revenu total de l’année de référence une fois que vous aurez quitté le mode de modification.',
    'Adjusting Income & Units for Teams': 'Ajustement des revenus et des transactions pour les équipes',
    'If you are adjusting the income or units for a team then you will need to adjust the income and/or units for the billing lead. If you change the income and/or units for a team member this will be considered a change to the individual’s income and not the team’s. If you are unsure who the billing lead is for a team please refer to the Manage Teams page.': 'Si vous ajustez les revenus ou les unités d\'une équipe, vous devrez ajuster le revenu ou le nombre d’unités au nom du responsable de la facturation.Si vous ajustez le revenu ou le nombre d’unités pour un membre de l\'équipe, cela sera considéré comme un changement pour l\'individu et non de l\'équipe. Si vous n\'êtes pas sûr de savoir qui est le responsable de la facturation pour une équipe, veuillez consulter la page Gérer les équipes.',
    'IMPORTANT: USERS OF LONEWOLF :  VERIFYING UNIT COUNTS': 'ATTENTION : UTILISATEURS DE LONEWOLF :  VÉRIFICATION DU NOMBRE DE TRANSACTIONS',
    'All trades uploaded to the FRS from BrokerWolf continue to be logged as sales transactions, even when they are not (such as leases).': 'Toutes les opérations téléchargées de BrokerWolf vers le SRF continuent d’être enregistrées comme des unités de vente, même si elles ne le sont pas (par exemple les baux).',
    'This results in inflated, and inaccurate unit counts.': 'Il en résulte un nombre de transactions exagéré et inexact.',
    'In order to accurately determine eligible units, it is ': 'Afin de déterminer avec précision les transactions éligibles, il est ',
    'CRITICAL': 'ESSENTIEL',
    'verify ': 'vérifiiez ',
    'the unit counts for ': 'le nombre de transactions pour ',
    'all ': 'tous ',
    'of your sales representatives who have unit counts ': 'vos courtiers immobiliers qui affichent un nombre de transactions ',
    'greater than 70 (greater than 60 in Alberta, Saskatchewan & Manitoba.)': '(supérieur à 70 (supérieur à 60 en Alberta, en Saskatchewan et au Manitoba.)',
    'Even if their unit numbers are correct, you need to confirm that their numbers have been vetted.': 'Même si le nombre d’unités de transaction est exact, vous devez confirmer que les chiffres ont été vérifiés.',
    'National Chairman’s Club & Top 10s: ': 'Le Club national des élites et le Club des dix: ',
    'The purpose of these fields is to flag users that you believe to be eligible, based on GCI and units, for one or more of these awards. If you believe the agent to be eligible for one or more of these awards simply check the box under the corresponding award. For Chairman’s and Top Ten contenders it’s especially important that you scroll to the bottom of the page to submit back up financials to support any change request you submitted.': 'Ces champs ont pour but de repérer les utilisateurs qui, selon vous, sont admissibles, en fonction des revenus de rétributions brutes et des unités de transaction, à l’une ou plusieurs de ces distinctions. Si vous pensez que le courtier est admissible pour une ou plusieurs de ces distinctions, cochez simplement la case sous la distinction correspondant. Pour les candidats au Club national des élites et au Club des dix, il est particulièrement important de faire défiler l’écran jusqu’au bas de la page pour soumettre des données financières à l’appui de toute demande de changement que vous avez soumise.',
    'Close': 'Rejeter',
    'Clicking this button confirms that all information in the form is correct and that unit counts for National Chairman\'s Club /Top Ten Award/Top 35 Under 35 Award candidates exclude leases and referral fees received and only include units resulting from sales.': 'En cliquant sur le bouton suivant, vous confirmez que toutes les informations contenues dans le présent formulaire sont véridiques et que les unités soumises pour le classement de vos candidats au Club national des élites, Club des 10 et Top 35 des moins de 35 exclues les locations et les références, pour n\'inclure que les unités reliées à une vente.',
    'Please submit a backup report for each agent you have flagged as potential candidates for the National Chairman\'s Club Award and /or Top 10 Award.': 'Si vous avez indiqué des candidats potentiels au Club national des élites et au Club des 10, veuillez soumettre les rapports de ventes ici.',
    'Confirmed successfully!': 'Confirmé avec succès !',
    'For users returning or joining from another Royal LePage brokerage, please select "REACTIVATE USER".': 'Pour les utilisateurs qui reviennent d\'un congé prolongé ou d\'une autre agence Royal LePage, veuillez cliquer sur le bouton « RÉACTIVER ».',
    'For new users, please continue and select "CREATE NEW USER".': 'Pour les nouveaux utilisateurs, veuillez continuer et sélectionner « NOUVEL UTILISATEUR ».',
    'REACTIVATE USER': 'RÉACTIVER',
    'CREATE NEW USER': 'NOUVEL UTILISATEUR',
    'ROYAL LEPAGE is a registered trademark of Royal Bank of Canada and is used under licence by Bridgemarq Real Estate Services Inc. and Bridgemarq Real Estate Services Manager Limited.': 'ROYAL LEPAGE est une marque de commerce déposée de la Banque Royale du Canada, utilisée sous license par Bridgemarq Real Estate Services Inc. et Bridgemarq Real Estate Services Manager Limited.',
    'based on': 'en',
    'GCI': 'revenu brut',
    'Units': 'unités',
    'GCI & Units': 'revenu brut et unités',
    'Important Reminder': 'Rappel important',
    '<b>TEAM MEMBERS: </b>team member trades that were written <u>BEFORE</u> the member joined the team CANNOT be added to the team lead for sales awards purposes since it is business written BEFORE joining the team.This income may be used by the team member to qualify for an individual award.': '<b>MEMBRES D’ÉQUIPE:</b> les transactions conclues par un courtier avant qu’il ne se joigne à l’équipe NE PEUVENT PAS être ajoutés aux revenus de l’équipe (donc du chef d’équipe). Les revenus générés par le courtier avant qu’il ne travaille en équipe seront considérés pour un prix individuel pour ce même courtier.',
    'IMPORTANT: USERS OF LONEWOLF (and EzMax & Nexone in Quebec)': 'IMPORTANT',
    'VERIFYING UNIT COUNTS FOR EVERY AGENT': 'VÉRIFICATION DES UNITÉS DE TRANSACTIONS',
    'All trades uploaded to the FRS from BrokerWolf continue to be logged as sales transactions, even when they are not sales.': 'Plusieurs opérations téléchargées vers le SRF continuent d’être enregistrées comme des unités de vente, même si elles ne le sont pas (par exemple les baux).',
    'In order to accurately determine eligible units, it is <b style="background-color:black;color:white">CRITICAL</b> that you <b><u>verify</u></b> the unit counts for <u>all of your sales representatives.</u>':
      'Afin de déterminer avec précision les transactions éligibles, il est <b style="background-color:black;color:white">ESSENTIEL</b> que vous <b><u>vérifiez</u></b> le nombre de transactions <u><b>pour tous</b> vos courtiers immobiliers susceptibles de se qualifier aux Tops 2%, 5% et 10% national, donc qui affichent un nombre de transactions égal ou supérieur à 20.</u>'
    , 'EXCEL': 'EXCEL',
    'Password is a required field. Remember that the password must have at least 8 characters and it should not exceed 40 characters. The Password must contain at least one uppercase letter, one lowercase letter, one digit and one special character': 'Mot de passe est un champ obligatoire. N\'oubliez pas que le mot de passe doit comporter au moins 8 caractères et ne doit pas dépasser 40 caractères. Il doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
    'Change Password': 'Changer le mot de passe',
    'Your password has been changed successfully.': 'Votre mot de passe a été changé avec succès.',
    'Error resetting password': 'Erreur lors de la réinitialisation du mot de passe',
    'Invalid Password': 'Mot de passe incorrect',
    'Password and Confirm Password do not match': 'Le mot de passe et le mot de passe de confirmation ne correspondent pas',
    '/customer-care-centre/': '/assistance-a-la-clientele/',
    'Team Member Start Date': 'Date de début du membre de l\'équipe',
    'Team Member End Date': 'Date de fin du membre de l\'équipe',
    'Billing Lead': 'Responsible for billing',
    'Launch RoyalMag': 'Lancer la RayalMag',

    'Password must have at least 8 characters and it must not exceed 40 characters.': 'Le mot de passe doit comporter au moins 8 caractères et ne doit pas dépasser 40 caractères.',
    'Password must contain at least one uppercase letter.': 'Le mot de passe doit contenir au moins une lettre majuscule.',
    'Password must contain at least one lowercase letter.': 'Le mot de passe doit contenir au moins une lettre minuscule.',
    'Password must contain at least one digit.': 'Le mot de passe doit contenir au moins un chiffre.',
    'Password must contain at least one special character.': 'Le mot de passe doit contenir au moins un caractère spécial.',
    'Confirm Password must be same as New Password.': 'Le mot de passe de confirmation doit être identique au nouveau mot de passe.',
    'Invalid New Password, The password must have at least 8 characters and it should not exceed 40 characters. The Password must contain at least one uppercase letter, one lowercase letter, one digit and one special character.': 'Mot de passe incorrect. Le mot de passe doit comporter au moins 8 caractères et ne doit pas dépasser 40 caractères. Il doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
    'Password may not contain username, first name or last name.': 'Le mot de passe ne doit pas contenir le nom d\'utilisateur, le prénom ou le nom de famille.',
    'X (Formerly Twitter)': 'X (anciennement Twitter)',
    'Home':'Accueil',
    'of': 'de',
    'results': 'résultats',
    'https://docs.rlpnetwork.com/rlpNetwork/Networking/GuestSpeaker/Find_a_Guest_Speaker_Resources_EN.pdf':'https://docs.rlpnetwork.com/rlpNetwork/Networking/GuestSpeaker/Find_a_Guest_Speaker_Resources_FR.pdf',
    'Click Here':'Cliquez ici',
    'for resources and tips on filling out your Guest Speaker Profile':'pour des ressources et des conseils pour remplir votre profil sur le répertoire des conférenciers',
    'home': 'accueil',
    'Royal LePage® is a registered trademark of Royal Bank of Canada and is used under licence by Bridgemarq Real Estate Services®. View important disclosures and notices about trademarks at <a href="http://rlp.ca/notices" target="_blank" style="color:#ee3e42">rlp.ca/notices</a>.':'Royal LePage<sup>MD</sup> est une marque de commerce déposée de la Banque Royale du Canada, utilisée sous licence par les Services Immobiliers Bridgemarq<sup>MD</sup>. Consultez les divulgations et les avis importants concernant les marques ici <a href="http://rlp.ca/avisjuridiquesetdecharges" target="_blank" style="color:#ee3e42">rlp.ca/avisjuridiquesetdecharges</a>.',
    'rlpNetwork<sup style="font-size:12pt">TM</sup>': 'Réseaurlp<sup style="font-size:12pt">MC</sup>',
    'Delete my Guest Speaker Profile': 'Supprimer mon profil de conférencier',
    'My Speaker\'s Spotlight Video Link':'Le lien vidéo de mon profil de conférencier',
    'References':'Références',
    'All information is required in references':'Veuillez fournir toutes les informations requises pour les références',
    'Areas of Expertise': 'Domaines d\'expertise',
    'Speaker\'s Spotlight':'Projecteur sur les orateurs',

  }
};
