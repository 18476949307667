import {
  getToken,
  setToken,
  toQueryString,
  serializeObject,
  get,
  post,
  postFile,
  serializeObjectEnhanced,
} from './fetch';

import jwt_decode from 'jwt-decode';

export { toQueryString, serializeObject, serializeObjectEnhanced };

export interface IProfile {
  email: string;
  token: string;
}
export interface ISession {
  user: IProfile;
  access?: IAccessToken;
}

export interface IAccessToken {
  aud: string;
  exp: number;
  firstname: string;
  iat: number;
  iss: string;
  lang: string;
  lastname: string;
  nbf: number;
  roles: string;
  sub: string;
  role: string[],
  userID: string,
  unique_name: string,
}

let accessMap;

export const auth = {
  token: (): string => getToken(),
  current: (): IAccessToken => {
    let jwt;
    const token = getToken();
    try {
      jwt = token ? jwt_decode<IAccessToken>(token) : null;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    return jwt;
  },
  signIn: async (user: { email: string; password: string }) => {
    const session = await post<ISession>('/users/login', { user }, false);
    setToken(session.user.token);
    try {
      const jwt = getToken() ? jwt_decode<IAccessToken>(getToken()) : null;
      //console.log(jwt);
      const roles = jwt.role;
      if (roles.includes('rlp_IngestSSO')) {
        const result = await get('/sso/jwt/' + 'IRE' + '/' + 'en');
        setToken(null);
        document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:01 GMT';
        window.location.replace(result);
        return 'stop';
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    accessMap = await get('/users/access');
    return session;
  },
  signOut: () => {
    setToken(null);
  },
  hasPermission: async (name: string, permission: string) => {
    if (!accessMap) {
      accessMap = await get('/users/access');
    }
    const accessRow = accessMap?.find(p => p.obj === name);
    return accessRow && accessRow.acc.includes(permission);
  },
  getCrmAuthParam: () => {
    return get('/sso/getauthparam/crm/');
  },
};

export const users = {
  search: (request, lang = 0) => {
    return post('/users/search/' + lang.toString(), request);
  },
  searchAdvance: (request) => {
    return post('/users/searchadvance', request);
  },
  Post: (request) => {
    return post('/users/', request);
  },
  checkEmailAvailability: (email) => {
    return get('/users/checkEmailAvailability/' + email + '/');
  },
  QuickLinks: (payload) => {
    return post('/users/addQuickLinks/', payload);
  },
  GetQuickLinks: (userEmail) => {
    return post('/users/getQuickLinks/', userEmail);
  },

  GetRoyalMag: (userEmail) => {
    return post('/users/getRoyalMag/', userEmail);
  },

  GetCompanyPersonId: (userId) => {
    return post('/users/getCompanyPersonId/', userId);
  },
  DeleteQuickLinks: (payload) => {
    return post('/users/deleteQuickLinks/', payload);
  },
  getUser: (email, personID) => {
    return get('/users/profile/' + email + '/' + personID);
  },
  getUserPropertyBag: (uname) => {
    return get('/users/properties/' + uname + '/');
  },
  getUserPhoto: (uname) => {
    return get('/users/photo/' + uname + '/');
  },
  resetProfilePassword: (payload) => {
    return post('/users/resetProfilePassword/', payload);
  },
  upsertUserProperty: (uname, model) => {
    return post('/users/properties/' + uname + '/', model);
  },
  deleteUserProperty: (uname, model) => {
    return post('/users/properties/del/' + uname + '/', model);
  },
  getAwardsTracking: (email) => {
    return post('/awardstracking/', email);
  },
  saveProfile: (model) => {
    return post('/users/saveProfile', model);
  },
  postUserAreas: (personId, model) => {
    return post('/users/' + personId + '/areaserviced', model);
  },
  postExpertTopic: (personId, model) => {
    return post('/users/' + personId + '/experttopic', model);
  },
  getUserLocations: (email) => {
    return get('/users/locations/' + email + '/');
  },
  getUsersList: (companyId, locationId, filter = '') => {
    return get('/users/' + (companyId ? companyId : 0) + '/' + (locationId ? locationId : 0) + '/' + filter);
  },
  getTerminateProfile: (email) => {
    return get('/users/terminateprofile/' + email + '/');
  },
  getPotentialTransferees: (email, terminateuseremail) => {
    return get('/users/potentialtransferees/' + email + '/' + terminateuseremail + '/');
  },
  terminateUser: (model) => {
    return post('/users/terminateUser', model);
  },
  getOneTimePwd: (id) => {
    return get('/users/impersonation/' + id + '/');
  },
  initiateForceSync: (id) => {
    return get('/users/forcesync/' + id + '/');
  },
  getUserCompany: (email) => {
    return get('/users/company/' + email + '/');
  },
  getUserSearchLocations: (email) => {
    return get('/users/searchlocations/' + email + '/');
  },
  isCustomerCare: (email) => {
    return get('/users/isCustomerCare/' + email + '/');
  },
  isManageUser: (email) => {
    return get('/users/isManageUser/' + email + '/');
  },
  getRecoveryEmail: (email) => {
    return get('/users/recoveryEmail/' + email + '/', false);
  },
  sendForgotPasswordInstructions: (email) => {
    return get('/users/sendForgotPasswordInstructions/' + email + '/', false);
  },
  resetPassword: (model) => {
    return post('/users/resetPassword', model, false);
  },
  getReactivateProfile: (email) => {
    return get('/users/reactivateprofile/' + email + '/');
  },
  reactivateUser: (model) => {
    return post('/users/reactivateUser', model);
  },
  setPassword: (personId, password) => {
    return post('/users/' + personId + '/' + 'setpassword', password);
  },
  getDomain: (model) => {
    return post('/users/getDomain', model);
  },
  saveExpertData: (model) => {
    return post('/users/saveExpertData', model);
  },
  getExpertData: (email) => {
    return get('/users/getExpertData/' + email + '/');
  },
  parkEmail: (id, park) => {
    return post('/users/parkemail/' + id + '/', park);
  },
  saveExpertNewEvent: (model) => {
    return post('/users/saveExpertNewEvent', model);
  },
  updateExpertEvents: (model) => {
    return post('/users/updateExpertEvents', model);
  },
  deleteExpertEvent: (model) => {
    return post('/users/deleteExpertEvent', model);
  },
  getCalendarInfo: (email) => {
    return get('/users/brokersharedcalendars/' + email + '/');
  },
  getPersonID: (email) => {
    return get('/users/personid/' + email + '/');
  },
  getGoogleDeleteList: () => {
    return get('/users/googledeleteforapproval/');
  },
  approveGoogleDelete: (model) => {
    return post('/users/approvegoogledelete', model);
  },

  getMenus: () => {
    return get('/WPObjAccess/GetMenuIdList');
  },

  getHeader: () => {
    return get('/users/getheader');
  },

  getTeams: (email, langCt) => {
    return get('/users/getteams/' + email + '/' + langCt);
  },

  getIreDashboardData: (personId) => {
    return get('/users/getIreDashboardData/' + personId);
  },

  getOldEmployeeDateHistory: (personId) => {
    return get('/users/getOldEmployeeDateHistory/' + personId);
  },

  getAuditEmployeeType: (personId) => {
    return get('/users/getAuditEmployeeType/' + personId);
  },

  getAuditLocation: (personId) => {
    return get('/users/getAuditLocation/' + personId);
  },

  getAuditPublicSiteInd: (personId) => {
    return get('/users/getAuditPublicSiteInd/' + personId);
  },

  getAuditPersonAwardPublicInd: (personId, awardId, year) => {
    return get('/users/getAuditPersonAwardPublicInd/' + personId + '/' + awardId + '/' + year);
  },

  getAuditTeamMember: (personId) => {
    return get('/users/getAuditTeamMember/' + personId);
  },

  getAuditNetworkMovement: (personId) => {
    return get('/users/getAuditNetworkMovement/' + personId);
  }

};

export const teams = {
  getTeamConstraints: (companyPersonID) => {
    return get('/team/getTeamConstraints/' + companyPersonID + '/');
  },
  updateTeamDate: (dateType, companyPersonID, teamMemberID, request) => {
    return post('/team/' + companyPersonID + '/updateTeamMembership/' + teamMemberID + '/' + dateType + '/', request);
  },
  getTeams: (companyId) => {
    return get('/team/' + (companyId ? companyId : ''));
  }
};

export const companies = {
  getAllCompanies: () => {
    return get('/Companies');
  },

  getAllTransferCompanies: () => {
    return get('/TransferCompanies');
  },

  getOpenCompanies: () => {
    return get('/OpenCompanies');
  },

  getCompanyDetail: (request) => {
    return get('/Companies/' + request);
  },

  post: (companyId, model) => {
    return post('/Companies/' + companyId, model);
  },

  getCompanyAddress: (request) => {
    return get('/Companies/' + request + '/address');
  },

  postCompanyAddress: (companyId, model) => {
    return post('/Companies/' + companyId + '/address', model);
  },

  getCompanyPhones: (request) => {
    return get('/Companies/' + request + '/phone');
  },

  postCompanyPhones: (companyId, model) => {
    return post('/Companies/' + companyId + '/phone', model);
  },

  getCompanyLocations: (request) => {
    return get('/Companies/' + request + '/locations');
  },
  getCompaniesByProvince: (provStateCt) => {
    return get('/Companies/Prov/' + provStateCt + '/');
  },
  getAllActiveNonAgents: (request) => {
    return get('/Companies/' + request + '/getallactivenonagents');
  },
  upsertProperty: (id, model) => {
    return post('/companies/properties/' + id + '/', model);
  },
  deleteProperty: (id, model) => {
    return post('/companies/properties/del/' + id + '/', model);
  },
  getRpt: (id, invoiceMonth) => {
    window.location.href = window['defaultBasePath'] + ('/companies/report/' + id + '/' + invoiceMonth + '?t=' + getToken());
  },
  getRptAvailableMonths: (id) => {
    return get('/Companies/reportmonths/' + id);
  },
  initiateForceSync: (id) => {
    return get('/companies/forcesync/' + id + '/');
  },
};

export const locations = {
  getAllLocations: () => {
    return get('/locations');
  },

  getLocationDetail: (request) => {
    return get('/locations/' + request);
  },

  getLocationPhones: (request) => {
    return get('/locations/' + request + '/phone');
  },

  getLocationsByCompanyID: (request) => {
    return get('/Companies/' + request + '/locations');
  },

  post: (locationId, model) => {
    return post('/locations/' + locationId, model);
  },

  getLocationAddress: (request) => {
    return get('/locations/' + request + '/address');
  },

  postLocationAddress: (locationId, model) => {
    return post('/locations/' + locationId + '/address', model);
  },

  postLocationAreas: (locationId, model) => {
    return post('/locations/' + locationId + '/areaserviced', model);
  },
  getProperties: (id) => {
    return get('/locations/properties/' + id + '/');
  },
  upsertProperty: (id, model) => {
    return post('/locations/properties/' + id + '/', model);
  },
  deleteProperty: (id, model) => {
    return post('/location/properties/del/' + id + '/', model);
  },
  getCompanyLocationsByUser: (id) => {
    return get('/locations/awardLocations/' + id + '/');
  },
  getAwardbyLocation: (companyId, locationId) => {
    return get('/locations/award/' + companyId + '/' + locationId + '/');
  },

  uploadAwards: (id: number, transferobj: object) => post('/locations/uploadAward/' + id + '/', transferobj),

  updateEligible: (model: object) => {
    return post('/locations/eligible/', model);
  },
  uploadAwardFile: async (companyId: number, locationId: number, formData: FormData): Promise<any> => {
    const res = await postFile<any>(`/locations/uploadAwardFile/${companyId}//${locationId}/`, formData);
    return res;
  },
  confirmAward: (model: object) => {
    return post('/locations/confirmAward/', model);
  },
};

export const phones = {
  getPhones: (objType, objId) => {
    return get('/phones/' + objType + '/' + objId);
  },

  postPhones: (objType, objId, model) => {
    return post('/phones/' + objType + '/' + objId, model);
  },
};

export const addresses = {
  getAddress: (objType, objId) => {
    return get('/address/' + objType + '/' + objId);
  },

  postAddress: (objType, objId, model) => {
    return post('/address/' + objType + '/' + objId, model);
  },
};

export const regions = {
  getAllRegions: () => {
    return get('/regionsserviced');
  },

  getRegions: (query, province) => {
    return get('/regionsserviced?query=' + query + '&province=' + province);
  },
};

export const commProg = {
  get: (personId) => {
    return get('/commprog/' + personId);
  },
  getExHistory: (personId) => {
    return get('/commprog/commfeeex/' + personId);
  },
  getCommFee: () => {
    return get('/commprog/commfee/');
  },
  upsertCommProg: (personId, model) => {
    return post('/commprog/' + personId, model);
  },
  upsertCommFeeEx: (personId, model) => {
    return post('/commprog/commfeeex/' + personId, model);
  },
  getCommConstraints: (companyPersonId) => {
    return get('/commprog/commconstraints/' + companyPersonId);
  },
};

export const ProtectionRoyale = {
  get: (personId) => {
    return get('/protectionroyale/' + personId);
  },
  upsertPRSubs: (personId, model) => {
    return post('/protectionroyale/' + personId, model);
  },
};

export const smartLead = {
  get: (companyId) => {
    return get('/companies/smartlead/' + companyId);
  },

  getSmartLeadFee: () => {
    return get('/companies/smartlead/commfee/');
  },
  upsertSmartLead: (model) => {
    return post('/companies/smartlead/', model);
  },
};

export const contractOption = {
  get: (locationId) => {
    return get('/contractoption/' + locationId);
  },

  upsertContractOption: (locationId, model) => {
    return post('/contractoption/' + locationId, model);
  },

  deleteContractOption: (locationId, model) => {
    return post('/deletecontractoption/' + locationId, model);
  },
};

export const feeException = {
  get: (personId) => {
    return get('/users/feeexception/' + personId);
  },

  upsertFeeException: (personId, model) => {
    return post('/users/feeexception/' + personId, model);
  },

  deleteFeeException: (personId, model) => {
    return post('/users/deletefeeexception/' + personId, model);
  },
};

export const obApi = {
  getOnboardingTypeOptions: () => {
    return get('/onboarding/getOnboardingTypeOptions');
  },

  transferAgents: (model) => {
    return post('/onboarding/rlp2rlp/transfer', model);
  },
};

export const powerBI = {
  getConfig: (name) => {
    return get('/powerbi/config/' + name);
  },
};

export const siteSearch = {
  simple: (query, lang = '') => {
    return get('/sitesearch/simplesearch?query=' + query.trim() + '&lang=' + lang.trim());
  },
};

export const referralSearch = {
  search: async (searchTerm) => {
    return get('/referralsearch?searchTerm=' + searchTerm);
  },

  searchByType: async (searchTerm, type, id, langCt) => {
    const res = await get(
      '/referralsearch?type=' + type + '&searchTerm=' + searchTerm + '&id=' + id + '&langCt=' + langCt.toString()
    );
    return res;
  },

  getPersonDetails: async (companyPersonID, langCt) => {
    const res = await get('/rfpersondetails/' + companyPersonID + '/' + langCt);
    return res;
  },

  getExpertDirectoryDetails: async (companyPersonID) => {
    const res = await get('/edpersondetails/' + companyPersonID);
    return res;
  },
};

export const expertsDirectorty = {
  getExpertsdirectory: async (lang) => {
    return get('/getExpertsdirectory/' + lang);
  },
};

export const Lookup = {
  getLookup: async () => {
    const response = await window['fetch']('/lookupAuto.json');
    const baseLookup = await response.json();
    const lkupAddData = await get('/regionsserviced/AdditionalLookupData');
    for (const lang of Object.keys(lkupAddData || {})) {
      const keys = Object.keys(lkupAddData[lang]);
      for (const key of keys) {
        const supplyObj = lkupAddData[lang][key];
        const lookupObj = baseLookup[lang][key];
        baseLookup[lang][key] = lookupObj.concat(supplyObj);
      }
    }
    return baseLookup;
  },
};

// WHY this ???
export const api = {
  invoke: (method, url, data) => {
    if (method === 'POST') {
      return post('/' + url, data);
    } else {
      return get('/' + url);
    }
  },
};

export async function doSSO(partnerId, partnerUrl, lang) {

  //console.log('In doSSO');
  switch (partnerId) {
  case 'TF':
  case 'LA':
  case 'LL':
  case 'IRE': {
    const result = await get('/sso/jwt/' + partnerId + '/' + lang);
    const url = partnerUrl === 'GetFromWebConfig' ? result : partnerUrl + '?token=' + result;
    window.open(url, '_blank');
    break;
  }
  case 'ADWERX':
  case 'RM': {
    window.open('/api/sso/' + partnerId + '/' + lang + ((partnerUrl) ? '?targetURL=' + partnerUrl : ''));
    break;
  }
  case 'IREPOST': {
    const result2 = await get('/sso/jwt/' + 'IRE' + '/' + lang);
    const jwt = document.createElement('input');
    jwt.value = result2.substring(result2.indexOf('?token=') + 7);
    jwt.name = 'jwt';
    const form = document.createElement('form');
    form.appendChild(jwt);
    form.method = 'POST';
    form.action = result2.substring(0, result2.indexOf('?token='));
    form.target = '_Blank';
    document.body.appendChild(form);
    form.submit();
    break;
  }
  case 'JUMP':
  case 'CARRIAGETRADE':
  case 'JUMPREPORTS':
  case 'JUMPLE':
  case 'SMARTSTUDIO':
  case 'JUMPQR':
  case 'CLIENTCLICK':
  case 'CLIENTCLICKDOMAINS':
  case 'JUMPCREATECOMMERCIAL':
  case 'JUMPCREATEINVESTMENT':
  case 'JUMPCREATEVACANT':
  case 'JUMPCREATEFARM':
  case 'COMMERCIALPROPERTIESWANTED':
  case 'PR':
    window.open(`${window['defaultBasePath']}/sso/xml/${partnerId}/${lang}?t=${getToken()}`, '_Blank');
    break;
  case 'JLR': {
    const jlrLang = lang === '1' ? 'fr' : 'en';
    window.open(`${window['defaultBasePath']}/sso/${partnerId}/${jlrLang}?t=${getToken()}`, '_Blank');
    break;
  }
  }
}
